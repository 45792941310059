import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'

import TextBox from '@atoms/TextBox'
import HorWrap from '@atoms/HorWrap'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import BlogArticle from '@molecules/BlogArticle'

import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const ProductPage = ({ data, transitionStatus }) => {
  const pageData = data.productPage
  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title={pageData.title} center>
          <h2>{pageData.header}</h2>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <HorWrap>CONTENT</HorWrap>
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query ProductPageBySlug($id: String) {
    productPage: datoCmsProductPage(id: { eq: $id }) {
      id
      title
      slug
      seoMetaTags {
        tags
      }
      header
    }
  }
`

export default ProductPage
